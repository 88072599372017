import { format } from 'date-fns';

export const stringToISOStringDate = (stringDate: string) => {
  const formattedDate = stringDate.includes('/')
    ? stringDate.split('/').join('-')
    : stringDate;
  const [day, month, year] = formattedDate.split('-');

  return new Date(Date.UTC(+year, +month - 1, +day)).toISOString();
};

export const getFormattedDate = (date: string) =>
  format(new Date(date), 'dd-MM-yyyy');
